export default function () {
    'use script';

    // https://github.com/AOEpeople/cookie-notice
    cookieNoticeJS({

        // Localizations of the notice message
        messageLocales: {
            de: 'Um die Webseite optimal gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.',
        },

        // Localizations of the dismiss button text
        buttonLocales: {
            de: 'OK',
        },

        // Position for the cookie-notifier (default=bottom)
        cookieNoticePosition: 'bottom',

        // Shows the "learn more button (default=false)
        learnMoreLinkEnabled: true,

        // The href of the learn more link must be applied if (learnMoreLinkEnabled=true)
        learnMoreLinkHref: 'datenschutzerklaerung.html',

        // The message will be shown again in X days
        expiresIn: 356,

        // Dismiss button background color
        buttonBgColor: '#972a2a',

        // Dismiss button text color
        buttonTextColor: '#fff',

        // Notice background color
        noticeBgColor: 'rgba(234, 234, 234, 1)',

        // Notice text color
        noticeTextColor: '#000',

        // the learnMoreLink color (default='#009fdd')
        linkColor: '#972a2a',

        // The target of the learn more link (default='', or '_blank')
        linkTarget: '_blank',

        // Print debug output to the console (default=false)
        debug: false,
    });
}
