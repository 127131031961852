import debug from './modules/debug';
import maps from './modules/maps';
import cookies from './modules/cookies';
import contact from './modules/contact';

debug();
cookies();

$(function() {
    maps();
    contact();
});
