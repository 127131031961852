export default function () {
    'use script';

    const apiKey = 'AIzaSyCPwXXTGyvs3uxVMwfYYEGD9tPI7hvmS9I';
    const lat = 48.6503547;
    const lng = 9.4567438;

    window.initMap = function () {
        const defaultOptions = {
            id: 'map',
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP, // ROADMAP | SATELLITE | HYBRID | TERRAIN
            scrollwheel: true,
            refreshOnResize: true,
            center: {
                lat,
                lng,
            },
            // icon: '/img/pin.png',
            showInfoOnLoad: true,
            showInfoOnClick: true,
        };

        const map = new google.maps.Map(document.getElementById(defaultOptions.id), {
            zoom: defaultOptions.zoom,
            center: defaultOptions.center,
            mapTypeId: defaultOptions.mapTypeId,
            scrollwheel: defaultOptions.scrollwheel,
        });

        const marker = new google.maps.Marker({
            position: defaultOptions.center,
            map,
            icon: defaultOptions.icon,
        });

        const link = 'https://www.google.com/maps/dir//' + lat + ',' + lng + '/@' + lat + ',' + lng + ',16z';
        const infoWindow = new google.maps.InfoWindow({
            content: '<h3>Kanzlei Braun-Ott</h3>'
                + '<p>Untere Steinstraße 12<br>'
                + '73230 Kirchheim unter Teck</p>'
                + '<p>Tel.: 07021–59820<br>'
                + 'Fax: 07021–83683</p>'
                + '<a href="' + link + '" target="_blank">zum Routenplaner</a>',
        });

        if (defaultOptions.showInfoOnClick) {
            google.maps.event.addListener(marker, 'click', function() {
                infoWindow.open(map, marker);
            });
        }

        if (defaultOptions.showInfoOnLoad && window.innerWidth >= 900) {
            infoWindow.open(map, marker);
        }

        // refresh on changed dimenstions
        if (defaultOptions.refreshOnResize) {
            google.maps.event.addDomListener(window, 'resize', function() {
                if (window.innerWidth >= 900) {
                    infoWindow.open(map, marker);
                } else {
                    infoWindow.close();
                }
                const center = map.getCenter();
                google.maps.event.trigger(map, 'resize');
                map.setCenter(center);
            });
        }
    };

    if ($('#map').length > 0) {
        $.getScript('https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=initMap');
    }
}
