export default function () {
    'use script';

    const $form = $('#contact-form');

    /**
     * On submit action
     */
    const formHandler = function() {
        $form.on('submit', (e) => {
            e.preventDefault();

            $.ajax({
                type: 'post',
                url: '/php/index.php',
                data: $form.serialize(),
                success: () => {
                    $form[0].reset();
                    $form.hide();
                    $('#contact-success').fadeIn();
                },
                error: () => {
                    $form[0].reset();
                    $form.hide();
                    $('#contact-error').fadeIn();
                },
            });
        });
    };

    /**
     * Show form after AJAX response
     */
    const appendShowFormAgainListener = function() {
        $('.contact__alert .btn').click(function() {
            $('.contact__alert').fadeOut();
            $form.fadeIn();
        });
    };

    if ($form.length > 0) {
        $.validate({
            lang: 'de',
            borderColorOnError: '#ce464a',
        });
        formHandler();
        appendShowFormAgainListener();
    }
}
